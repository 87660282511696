.intro {
    --nextui-foreground: #000000;
    // background-image: url('/blog2.jpg');
    background-color: #000000;
    background-size: cover;
    background-position: center;
    // background-color: red;
}



